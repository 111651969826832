<template lang="html">
  <div>
    <div class="vx-row">
      <div class="vx-col w-full mb-base">
        <b-card :title="$t('search.immutablePhrase.header')">

          <immutable-phrase-create-popup />

          <immutable-phrases-table
            api-endpoint="/search/immutablePhrase"
            :columns="immutablePhrases.columns"
            :default-sort="immutablePhrases.defaultSort"
            :default-order-by="immutablePhrases.defaultOrderBy"
          />
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import ImmutablePhrasesTable from '@/views/components/search/ImmutablePhrases/ImmutablePhrasesTable.vue'
import ImmutablePhraseCreatePopup from '@/views/components/search/ImmutablePhrases/ImmutablePhraseCreatePopup.vue'

export default {
  components: {
    ImmutablePhraseCreatePopup,
    ImmutablePhrasesTable,
  },
  data() {
    return {
      immutablePhrases: {
        defaultSort: 'asc',
        defaultOrderBy: 'phrase',
        columns: [
          {
            displayName: this.$t('search.immutablePhrase.phraseColumnHeader'),
            name: 'phrase',
          },
        ],
      },
    }
  },
}
</script>
