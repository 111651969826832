<template>
  <div>
    <vs-button class="mb-2" @click="showPopup()">
      {{ $t('search.immutablePhrase.addNew') }}
    </vs-button>
    <vs-popup
      class="immunePhrasesCreatePopup"
      :title="$t('search.immutablePhrase.createPopupHeader')"
      :button-close-hidden="true"
      :active.sync="active"
    >
      <b-overlay :show="dataLoading" spinner-variant="primary" variants="white" opacity="0.6">
        <label>{{ $t('search.immutablePhrase.createPopupLabel') }}</label>
        <vs-row>
          <vs-col class="mb-4 w-full">
            <div v-for="(immutablePhrases, index) in immutablePhrases" :key="index" ref="immunePhrasesInputContainer">
              <vs-row class="mb-1">
                <vs-col vs-w="11">
                  <vs-input
                    :key="index"
                    v-model="immutablePhrases.phrase"
                    class="w-100"
                    size="sm"
                    :danger="!immutablePhrases.isValid"
                    :danger-text="$t('search.immutablePhrase.popupInvalid')"
                  />
                </vs-col>
                <vs-col
                  v-if="isNotFirstImmutablePhrase(index)"
                  vs-w="1"
                  vs-type="flex"
                  vs-justify="flex-end"
                  vs-align="center"
                >
                  <b-button
                    variant="danger"
                    class="btn-icon"
                    size="sm"
                    @click.prevent="removeImmutablePhraseInput(index)"
                  >
                    <feather-icon icon="Trash2Icon" />
                  </b-button>
                </vs-col>
              </vs-row>
              <div
                v-if="immutablePhrases.length - 1 > index"
                class="text-center"
              >
                {{ $t('search.immutablePhrase.or') }}
              </div>
            </div>
            <div class="text-left">
              <b-button variant="primary" size="sm" @click.prevent="addImmutablePhraseInput()">
                <feather-icon icon="PlusCircleIcon" />
                {{ $t('search.immutablePhrase.addInputTooltip') }}
              </b-button>
            </div>
          </vs-col>
        </vs-row>
        <vs-row>
          <vs-col vs-w="2" vs-offset="8">
            <b-button variant="primary" @click="save()">
              {{ $t('search.immutablePhrase.editPopupSave') }}
            </b-button>
          </vs-col>
          <vs-col vs-w="2">
            <b-button variant="outline-primary" @click="hidePopup()">
              {{ $t('search.immutablePhrase.editPopupCancel') }}
            </b-button>
          </vs-col>
        </vs-row>
      </b-overlay>
    </vs-popup>
  </div>
</template>

<script>
import apiRequest from '@/apiRequest'
import store from '@/store'

export default {
  data() {
    return {
      active: false,
      immutablePhrases: [{ phrase: '', isValid: true }],
      dataLoading: false,
    }
  },
  methods: {
    filterNonEmptyImmutablePhrases() {
      this.immutablePhrases = this.immutablePhrases.filter((immutable, index) => index === 0 || immutable.phrase !== '')
    },
    hidePopup() {
      this.active = false
      this.filterNonEmptyImmutablePhrases()
    },
    showPopup() {
      this.active = true
    },
    isNotFirstImmutablePhrase(index) {
      return index !== 0
    },
    addImmutablePhraseInput() {
      this.immutablePhrases.push({ phrase: '', isValid: true })
    },
    removeImmutablePhraseInput(index) {
      this.immutablePhrases.splice(index, 1)
    },
    save() {
      this.dataLoading = true

      this.filterNonEmptyImmutablePhrases()

      if (!this.validateImmutablePhrases()) {
        this.dataLoading = false
        return
      }

      const requestData = {
        immutable: this.immutablePhrases.map(immutable => immutable.phrase),
      }
      apiRequest
        .post('search/immutablePhrase', requestData)
        .then(() => {
          this.immutablePhrases = [{ phrase: '' }]
          this.dataLoading = false

          this.hidePopup()

          store.commit('notifications/addPopupInfo', {
            name: this.$t('search.immutablePhrase.insertSuccessfulNotificationTitle'),
            message: this.$t('search.immutablePhrase.insertSuccessfulNotification'),
          })

          this.$root.$emit('immutablePhraseCreated')
        })
        .catch(error => {
          store.commit('notifications/addError', error)
          this.dataLoading = false
        })
    },
    validateImmutablePhrases() {
      let allValid = true
      this.immutablePhrases.forEach((immutablePhrase, index) => {
        allValid = allValid && immutablePhrase.phrase.length > 0
        this.immutablePhrases[index].isValid = immutablePhrase.phrase.length > 0
      })
      return allValid
    },
  },
}
</script>
