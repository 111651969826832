<template>
  <div ref="container">
    <b-overlay :show="dataLoading" spinner-variant="primary" variants="white" opacity="0.6">

      <vs-table :data="results" stripe class="mb-base mb-2">
        <template v-if="title" slot="header">
          <h3>{{ title }}</h3>
        </template>
        <template slot="thead">
          <vs-th v-for="(column, columnIndex) in columns" :key="columnIndex">
            <span :class="{ 'cursor-default' : column.disableOrderBy}" @click="manageOrder(column)">
              {{ column.displayName }}
              <i
                v-if="isOrderByThisColumnActive(column.name)"
                class="vs-icon notranslate icon-scale th-sort-icon material-icons null"
              >{{ getArrowUpOrDown() }}</i>
            </span>
          </vs-th>
          <vs-th>{{ $t('search.immutablePhrase.optionsHeader') }}</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr
            v-for="(tr, rowIndex) in data"
            :id="`row-${data[rowIndex].id}`"
            :key="rowIndex"
          >
            <vs-td
              v-for="(column, columnIndex) in columns"
              :key="columnIndex"
              :data="data[rowIndex][column.name]"
              :class="column.class"
            >
              {{ data[rowIndex][column.name] }}
            </vs-td>
            <vs-td>
              <a href="#" class="mr-1" @click.prevent="edit(rowIndex)">
                <vs-tooltip :text="$t('search.immutablePhrase.editTooltip')">
                  <edit-icon class="text-primary" />
                </vs-tooltip>
              </a>
              <a href="#" @click.prevent="confirmDelete(rowIndex)">
                <vs-tooltip :text="$t('search.immutablePhrase.deleteTooltip')">
                  <trash-2-icon class="text-danger" />
                </vs-tooltip>
              </a>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </b-overlay>
    <vs-pagination v-model="page" :total="totalPages" :max="7" />

    <vs-popup
      class="blockPhraseEditPopup"
      :title="$t('search.immutablePhrase.editPopupHeader')"
      :active.sync="popup.active"
    >
      <div ref="popup">
        <vs-row>
          <vs-col class="mb-5 w-full">
            <label>{{ $t('search.immutablePhrase.editPopupLabel') }}</label>
            <vs-input
              v-model="popup.immutablePhrase" class="w-100"
              :danger="popup.immutablePhrase.length < 1"
              :danger-text="$t('search.immutablePhrase.popupInvalid')"
            />
          </vs-col>
        </vs-row>
        <vs-row>
          <vs-col vs-w="2" vs-offset="8">
            <b-button variant="primary" @click="save()">
              {{ $t('search.immutablePhrase.editPopupSave') }}
            </b-button>
          </vs-col>
          <vs-col vs-w="2">
            <b-button variant="outline-primary" @click="hideEditPopup()">
              {{ $t('search.immutablePhrase.editPopupCancel') }}
            </b-button>
          </vs-col>
        </vs-row>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import apiRequest from '@/apiRequest'
import store from '@/store'
import { EditIcon, Trash2Icon } from 'vue-feather-icons'

export default {
  components: {
    EditIcon, Trash2Icon,
  },
  props: [
    'columns', 'title', 'defaultSort', 'defaultOrderBy', 'rowsPerPage',
  ],
  data() {
    return {
      popup: {
        active: false,
        rowIndex: null,
        immutablePhrase: '',
      },
      limit: this.rowsPerPage || 50,
      page: 1,
      results: [],
      totalPages: 1,
      sort: this.defaultSort || 'asc',
      orderBy: this.defaultOrderBy || 'default',
      dataLoading: true,
    }
  },
  watch: {
    page() {
      this.getData()
    },
  },
  mounted() {
    this.getData()
    this.$root.$on('immutablePhraseCreated', () => {
      this.getData()
    })
  },
  methods: {
    isOrderByThisColumnActive(columnName) {
      return this.orderBy === columnName
    },
    getArrowUpOrDown() {
      return this.sort === 'asc' ? 'expand_less' : 'expand_more'
    },
    manageOrder(column) {
      if (typeof column.disableOrderBy !== 'undefined' && column.disableOrderBy) {
        return
      }
      if (this.orderBy === column.name) {
        if (this.sort === 'asc') {
          this.sort = 'desc'
        } else {
          this.sort = 'asc'
        }
        this.getData()
        return
      }
      this.orderBy = column.name
      this.sort = 'desc'

      this.getData()
    },
    getData() {
      this.dataLoading = true

      const requestData = new URLSearchParams({
        sort: this.sort,
        orderBy: this.orderBy,
        page: this.page,
      })

      apiRequest
        .get(`search/immutablePhrase?${requestData.toString()}`)
        .then(result => {
          this.results = result.data.data
          this.totalPages = Math.ceil(result.data.total / this.limit)

          this.dataLoading = false
        })
        .catch(error => {
          store.commit('notifications/addError', error)
          this.dataLoading = false
        })
    },
    edit(rowIndex) {
      this.popup.active = true
      this.popup.rowIndex = rowIndex
      this.popup.immutablePhrase = this.results[rowIndex].phrase
    },
    save() {
      this.dataLoading = true

      if (!this.popup.immutablePhrase.length) {
        this.dataLoading = false
        return
      }

      const { rowIndex } = this.popup
      const requestData = {
        immutablePhrase: this.popup.immutablePhrase,
      }

      apiRequest
        .put(`search/immutablePhrase/${this.results[rowIndex].phrase}`, requestData)
        .then(() => {
          this.updateDataInRow(rowIndex)

          this.popup.synonymId = null
          this.popup.immutablePhrase = ''

          this.hidePopup()

          store.commit('notifications/addPopupInfo', {
            name: this.$t('search.immutablePhrase.saveSuccessfulNotificationTitle'),
            message: this.$t('search.immutablePhrase.saveSuccessfulNotification'),
          })

          this.dataLoading = false
        })
        .catch(error => {
          store.commit('notifications/addError', error)
          this.dataLoading = false
        })
    },
    hidePopup() {
      this.popup.synonymId = null
      this.popup.active = false
    },
    updateDataInRow(rowIndex) {
      this.results[rowIndex].phrase = this.popup.immutablePhrase
    },
    confirmDelete(rowIndex) {
      const self = this
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: self.$t('search.immutablePhrase.deleteConfirmationHeader'),
        text: self.$t('search.immutablePhrase.deleteConfirmationText'),
        acceptText: self.$t('search.immutablePhrase.deleteConfirmationAcceptText'),
        cancelText: self.$t('search.immutablePhrase.deleteConfirmationCancelText'),
        accept() {
          self.delete(rowIndex)
        },
      })
    },
    delete(rowIndex) {
      this.dataLoading = true
      const deleteKey = `${this.results[rowIndex].phrase}`

      apiRequest
        .delete(`search/immutablePhrase/${deleteKey}`)
        .then(() => {
          this.results.splice(rowIndex, 1)

          this.dataLoading = false
        })
        .catch(error => {
          store.commit('notifications/addError', error)
          this.dataLoading = false
        })
    },
  },

}
</script>

<style>
.vs-pagination--ul {
  margin-bottom: 0;
}

.vs-pagination--li {
  width: auto;
  min-width: 35px;
}

.vs-pagination--li.is-current {
  padding-left: 3px;
  padding-right: 5px;
  min-width: 35px;
  border-radius: 35px;
}

.vs-pagination--li.is-current .effect {
  border-radius: 35px;
}

.con-vs-tooltip {
  display: inline-block
}
</style>
